import Body from "./component/Body";
import Psu_Information_Context_Provider from "./context/Psu_Information_Context_Provider";
import AuthContextProvider from "./context/authContextProvider";
import React, { Suspense } from "react";
import Loader from "./SubComponent/Loader";

function App() {
  React.useEffect(() => {
    showCookies("BEFORE");
    showCache("BEFORE");
    const currentVersion = window.localStorage.getItem("VERSION");
    const newVersion = document.getElementById("project_version")?.value;
    console.log("currentVersion", currentVersion);
    console.log("newVersion", newVersion);
    if (currentVersion !== newVersion) {
      clearCookies();
      clearCacheData();
      window.localStorage.removeItem("VERSION");
      window.location.reload(true);
      window.localStorage.setItem("VERSION", newVersion);
    }
  });

  // To console saved cookies, FOR TESTING PURPOSE ONLY
  const showCookies = (message) => {
    console.log("COOKIE", message, document.cookie);
  };

  const clearCookies = () => {
    var Cookies = document.cookie.split(";");
    for (var i = 0; i < Cookies.length; i++)
      document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
    showCookies("AFTER");
  };

  // To console saved cache, FOR TESTING PURPOSE ONLY
  const showCache = (message) => {
    console.log("CACHE", message, caches);
  };

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    showCache("AFTER");
  };

  return (
    <Suspense fallback={<Loader />}>
      <AuthContextProvider>
        <Psu_Information_Context_Provider>
          <Body />
        </Psu_Information_Context_Provider>
      </AuthContextProvider>
    </Suspense>
  );
}

export default App;
