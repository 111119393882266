import axios from "axios";
import localStorageUtil from "../../Utils/localStorageUtil";
const KEYCLOAK_BASE_URI = process.env.REACT_APP_KEYCLOAK_BASE_URI;
const KEYCLOAK_CLIENT_SECRET = process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET;

export const utils = {
  loginByUserPass: (username, password) => {
    return axios.request({
      method: "post",
      maxBodyLength: Infinity,
      url: `${KEYCLOAK_BASE_URI}/realms/master/protocol/openid-connect/token`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: {
        client_id: "admin-cli",
        username,
        password,
        grant_type: "password",
        client_secret: KEYCLOAK_CLIENT_SECRET,
      },
    });
  },

  updateToken : (refreshToken) => {
    return axios.request({
      method: "post",
      maxBodyLength: Infinity,
      url: `${KEYCLOAK_BASE_URI}/realms/master/protocol/openid-connect/token`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: {
        client_id: "admin-cli",
        grant_type: "refresh_token",
        client_secret: KEYCLOAK_CLIENT_SECRET,
        refresh_token: refreshToken,
      },
    })
  },

  createUser: (value) => {
    return axios.request({
      method: "post",
      headers: {
        Authorization: "Bearer " + localStorageUtil.getAccessToken(),
      },
      maxBodyLength: Infinity,
      url: `${KEYCLOAK_BASE_URI}/admin/realms/master/users`,
      data: {
        username: value?.user_id,
        enabled: true,
        firstName: value?.first_name,
        lastName: value?.last_name,
        email: value?.email,

        attributes: {
          first_name: value?.first_name,
          last_name: value?.last_name,
          email: value?.email,
          password: value?.password,
          roleType: value?.roleType,
          company_id: value?.company_id,
          unit_id: value?.unit_id,
          emp_id: value?.emp_id,
          phone_number: value?.phone_number,
        },
        credentials: [
          { type: "password", value: value?.password, temporary: false },
        ],
      },
    });
  },

  getAvaliableRoles: (userId) => {
    return axios.request({
      method: "get",
      maxBodyLength: Infinity,
      url: `${KEYCLOAK_BASE_URI}/admin/realms/master/users/${userId}/role-mappings/realm/available`,
      headers: {
        Authorization: "Bearer " + localStorageUtil.getAccessToken(),
      },
    });
  },

  assignUserRole: (roles, userId, roleType) => {
    const roleToAssign = roles.filter((role) => role.name == roleType)?.at(0);
    let config = {
      method: "POST",
      maxBodyLength: Infinity,
      url: `${KEYCLOAK_BASE_URI}/admin/realms/master/users/${userId}/role-mappings/realm/`,
      data: [roleToAssign],
      headers: {
        Authorization: "Bearer " + localStorageUtil.getAccessToken(),
      },
    };
    return axios.request(config);
  },
  getAllUsers: () => {
    return axios.get(`${KEYCLOAK_BASE_URI}/admin/realms/master/users`, {
      headers: {
        Authorization: "Bearer " + localStorageUtil.getAccessToken(),
      },
    });
  },
  disableUser: (userId, allUsers) => {
    const userDetails = allUsers.filter((user) => user.id === userId)?.at(0);

    userDetails["enabled"] = false;

    return axios.request({
      url: KEYCLOAK_BASE_URI + "/admin/realms/master/users/" + userDetails.id,
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorageUtil.getAccessToken(),
      },
      data: userDetails,
    });
  },
  enableUser: (userId, allUsers) => {
    const userDetails = allUsers.filter((user) => user.id === userId)?.at(0);

    userDetails["enabled"] = true;

    return axios.request({
      url: KEYCLOAK_BASE_URI + "/admin/realms/master/users/" + userDetails.id,
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorageUtil.getAccessToken(),
      },
      data: userDetails,
    });
  },
  resetPassword: (userId, new_password) => {
    return axios.request({
      url:
        KEYCLOAK_BASE_URI +
        "/admin/realms/master/users/" +
        userId +
        "/reset-password",
      method: "PUT",
      data: {
        value: new_password,
        type: "password",
        temporary: false,
      },
      headers: {
        Authorization: "Bearer " + localStorageUtil.getAccessToken(),
      },
    });
  },
};
