import axios from "axios";
import localStorageUtil from "../Utils/localStorageUtil";
const axios_instance = axios.create({
  // baseURL: "https://10.227.101.109:8080",
  baseURL: process.env.REACT_APP_BACKEND_URI,
});

axios_instance.interceptors.request.use((config) => {
  if (localStorageUtil.isToken()) {
    config.headers["Authorization"] =
      "Bearer " + localStorageUtil.getAccessToken();
    return config;
  }
  return config;
});
export default axios_instance;
