const TOKEN_NAME = "ENERGY_EFFICIENCY_DASHOBARD_TOKEN";

const localStorageUtil = {
  addToken: (token) => {
    window.localStorage.setItem(TOKEN_NAME, token);
  },
  removeToken: () => {
    window.localStorage.removeItem(TOKEN_NAME);
  },
  isToken: () => {
    return window.localStorage.getItem(TOKEN_NAME) ? true : false;
  },
  getToken: () => {
    return window.localStorage.getItem(TOKEN_NAME);
  },
  getAccessToken: () => {
    return JSON.parse(localStorageUtil.getToken())["accessToken"][
      "access_token"
    ];
  },
  clear: () => {
    window.localStorage.clear();
  },
};

export default localStorageUtil;
