import React, { useEffect, useState } from "react";
import authContext from "./authContext";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import localStorageUtil from "../Utils/localStorageUtil";
import { message } from "antd";
import axios_instance from "../Services/axios_instance";
import { utils as KeycloakAdminUtils } from "../component/UserConsole/services";
const AuthContextProvider = ({ children }) => {
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [userRawData, setUserRawData] = useState({});
  const [userDataDecoded, setUserDataDecoded] = useState({});

  const login = (username, password) => {
    setIsAuthLoading(true);

    KeycloakAdminUtils.loginByUserPass(username, password)
      .then((response) => {
        let t = {
          accessToken: response?.data,
          expiresOn: new Date(Date.now() + response?.data?.expires_in * 1000),
          refreshToken: response?.data?.refresh_token,
          refreshTokenExpiresOn: new Date(
            Date.now() + response?.data?.refresh_expires_in * 1000
          ),
          token_type: response?.data?.token_type,
        };
        setIsAuth(true);
        setUserRawData(t);
        localStorageUtil.clear();
        localStorageUtil.addToken(JSON.stringify(t));
        const newVersion = document.getElementById("project_version")?.value;
        window.localStorage.setItem("VERSION", newVersion);
      })
      .catch((error) => {
        message?.error(
          error?.response?.data?.error_description ||
            "Authentication server unreachable"
        );
        logout();
      })
      .finally(() => {
        setIsAuthLoading(false);
      });
  };

  const updateToken = (refreshToken) => {
    KeycloakAdminUtils.updateToken(refreshToken)
      .then((response) => {
        let t = {
          accessToken: response?.data,
          expiresOn: new Date(Date.now() + response?.data?.expires_in * 1000),
          refreshToken: response?.data?.refresh_token,
          refreshTokenExpiresOn: new Date(
            Date.now() + response?.data?.refresh_expires_in * 1000
          ),
          token_type: response?.data?.token_type,
        };
        setIsAuth(true);
        setUserRawData(t);
        localStorageUtil.clear();
        localStorageUtil.addToken(JSON.stringify(t));
        const newVersion = document.getElementById("project_version")?.value;
        window.localStorage.setItem("VERSION", newVersion);
      })
      .catch((error) => {
        message?.error(error?.response?.data?.error_description);
        logout();
      });
  };

  const logout = () => {
    setIsAuthLoading(true);
    localStorageUtil.clear();
    // const newVersion = document.getElementById("project_version")?.value;
    // window.localStorage.setItem("VERSION", newVersion);
    setIsAuth(false);
    setUserRawData({});
    setIsAuthLoading(false);
  };

  useEffect(() => {
    if (!isAuth) return;
    const t = setInterval(() => {
      // console.log("Refresh - ", new Date(userRawData?.refreshTokenExpiresOn));
      // console.log("Access - ", new Date(userRawData?.expiresOn));
      // console.log("Current - ", new Date());
      // console.log("-------------------------------");

      if (new Date(userRawData?.refreshTokenExpiresOn) < new Date()) {
        // console.log("Refresh token exp");
        logout();
        return;
      }

      if (new Date(userRawData?.expiresOn) < new Date()) {
        // console.log("Access token exp");
        updateToken(userRawData?.refreshToken);
      }
    }, 5 * 1000);

    return () => {
      clearInterval(t);
    };
  }, [userRawData]);

  useEffect(() => {
    setIsAuthLoading(true);
    if (localStorageUtil.isToken()) {
      setUserRawData(JSON.parse(localStorageUtil.getToken()));
      setIsAuth(true);
    }
    setIsAuthLoading(false);
  }, []);

  useEffect(() => {
    setIsAuthLoading(true);
    decodeUserData();
    setIsAuthLoading(false);
  }, [userRawData]);

  const decodeUserData = () => {
    setIsAuthLoading(true);
    if (!userRawData?.accessToken?.access_token) return;

    let decode = jwtDecode(userRawData?.accessToken?.access_token);

    if (decode?.realm_access?.roles?.find((role) => role === "unit")) {
      axios_instance
        .get(`/api/dpsu?unit_id=${decode?.unit_id}`)
        .then((response) => {
          setUserDataDecoded({
            dpsu_info: response?.data,
            company_id: decode?.company_id,
            last_name: decode?.last_name,
            phone_number: decode?.phone_number,
            first_name: decode?.first_name,
            emp_id: decode?.emp_id,
            username: decode?.preferred_username,
            roles: decode?.realm_access?.roles || [],
          });
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
        });
    } else
      setUserDataDecoded({
        dpsu_info: {},
        company_id: decode?.company_id,
        last_name: decode?.last_name,
        phone_number: decode?.phone_number,
        first_name: decode?.first_name,
        emp_id: decode?.emp_id,
        username: decode?.preferred_username,
        roles: decode?.realm_access?.roles || [],
      });

    setIsAuthLoading(false);
  };

  return (
    <authContext.Provider
      value={{ login, logout, isAuthLoading, isAuth, userDataDecoded }}
    >
      {children}
    </authContext.Provider>
  );
};

export default AuthContextProvider;
