import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

const UserConsole = React.lazy(() => import("./UserConsole/Index"));
const NewUserRegistration = React.lazy(() =>
  import("./UserConsole/NewUserRegistration")
);
const ResetPassword = React.lazy(() => import("./UserConsole/ResetPassword"));

const EnableDisableUser = React.lazy(() =>
  import("./UserConsole/EnableDisableUser")
);

const DashboardLayout = React.lazy(() => import("./Dashboard/Index"));
const Charts = React.lazy(() => import("./Charts/Index"));
const OperatorConsole = React.lazy(() => import("./OperatorConsole/Index"));
const Login = React.lazy(() => import("./Login/index"));
const Support = React.lazy(() => import("./Support"));
const ResetPasswordForUser = React.lazy(() =>
  import("./UserConsole/ResetPasswordForUser")
);
const Download = React.lazy(() => import("./Download"));
const KpiDashboard = React.lazy(() => import("./KpiDashboard/index"));
const QuickAnalysis = React.lazy(() => import("./QuickAnalysis/index"));

const DashboardLanding = React.lazy(() => import("./DashboardLanding/index"));
const Certification = React.lazy(() => import("./Certification/index"));
const EnergyAccumulation = React.lazy(() => import("./EnergyAccumulation"));
const Body = () => {
  const router = createBrowserRouter(
    [
      {
        index: true,
        element: <Login />,
      },
      {
        element: <DashboardLayout />,
        children: [
          {
            path: "energy-accumulation",
            element: <EnergyAccumulation />,
          },
          {
            path: "contact-us",
            element: <Support />,
          },
          {
            path: "download",
            element: <Download />,
          },
          {
            path: "dashboard",
            element: <DashboardLanding />,
          },

          {
            path: "kpi-dashboard",
            element: <KpiDashboard />,
          },
          {
            path: "quick-analysis",
            element: <QuickAnalysis />,
          },
          {
            path: "certification",
            element: <Certification />,
          },
          {
            path: "analytics",
            element: <Charts />,
          },
          {
            path: "operatorform",
            element: <OperatorConsole key={window.location.pathname} />,
          },
          {
            path: "admin-console",
            element: <UserConsole />,
            children: [
              {
                path: "new-user",
                element: <NewUserRegistration />,
              },
              {
                path: "reset-password",
                element: <ResetPassword />,
              },
              {
                path: "edit-user",
                element: <EnableDisableUser />,
              },
            ],
          },
          {
            path: "user-console",
            children: [
              {
                path: "reset-password",
                element: <ResetPasswordForUser />,
              },
            ],
          },
          // {
          //   path: "reports",
          //   element: <Index_reports />,
          // },
        ],
      },

      {
        path: "*",
        element: <div>Error Page</div>,
      },
    ],
    {}
  );

  return <RouterProvider router={router}></RouterProvider>;
};

export default Body;
