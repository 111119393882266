import React, { useState } from "react";
import Psu_Information from "./Psu_Information_Context";
import dayjs from "dayjs";

const Psu_Information_Context_Provider = ({ children }) => {
  const [list_of_dpsu, set_list_of_dpsu] = useState([]);
  const [selectedRenewableGraph, set_SelectedRenewableGraph] = useState(1);
  const [selectedPsu, set_SelectedPsu] = useState(0);
  const [fin_Year, set_Fin_Year] = useState(0);
  const [fromFinancialYear, setFromFinancialYear] = useState("2021");
  const [toFinancialYear, setToFinancialYear] = useState(dayjs().year().toString());
  const [compareTrueFalse, setCompareTrueFalse] = useState(false);

  // Graph State Flag Meaning

  // 0=overall Data yearwise
  // 1=PSU wise data for selected financial Year
  // 2=Unitwise data for selected PSU and financial year

  const [graph_State_Flag, set_Graph_State_Flag] = useState(0);

  return (
    <Psu_Information.Provider
      value={{
        list_of_dpsu,
        set_list_of_dpsu,
        selectedRenewableGraph,
        set_SelectedRenewableGraph,
        selectedPsu,
        set_SelectedPsu,
        fin_Year,
        set_Fin_Year,
        graph_State_Flag,
        set_Graph_State_Flag,
        fromFinancialYear,
        setFromFinancialYear,
        toFinancialYear,
        setToFinancialYear,
        compareTrueFalse,
        setCompareTrueFalse,
      }}
    >
      {children}
    </Psu_Information.Provider>
  );
};

export default Psu_Information_Context_Provider;
